<template>
  <v-btn
    :disabled="disabled"
    @click="handleClick"
    id="btn-investor-table"
    :loading="loading"
  >
    <font-awesome-icon v-if="icon" :icon="icon" class="btn-icon" />
    {{ title }}
  </v-btn>
</template>

<script>
export default {
  name: "BtnInvestorTable",
  props: {
    title: String,
    loading: Boolean,
    icon: String,
    disabled: Boolean,
  },
  methods: {
    handleClick() {
      this.$emit("btnFunction");
    },
  },
};
</script>

<style scoped>
#btn-investor-table {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--primary);

  display: flex;
  align-items: center;

  width: 100%;
  height: inherit;
  padding: 10px 20px;
  background: var(--white);
  border: 1px solid var(--primary);
  border-radius: 8px;
}

#btn-investor-table:hover {
  background: var(--primary);
  color: var(--dark);
  border-color: var(--primary);
}

#btn-investor-table:disabled {
  border-color: var(--white);
}

.btn-icon {
  margin-right: 8px;
}
</style>
